import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/styles.css'; // Подключаем стили

const TehnikaEditor = () => {
  const [tehnika, setTehnika] = useState([]);
  const [newTehnika, setNewTehnika] = useState({
    name: '',
    description: '',
    client: '',
  });
  const [editingTehnika, setEditingTehnika] = useState(null);

  useEffect(() => {
    fetchTehnika();
  }, []);

  const fetchTehnika = async () => {
    try {
      const response = await axios.get('/api/tehnika');
      setTehnika(response.data.tehnika);
    } catch (error) {
      console.error('Ошибка получения техники:', error);
    }
  };

  const addTehnika = async () => {
    try {
      await axios.post('/api/tehnika', newTehnika);
      setNewTehnika({
        name: '',
        description: '',
        client: '',
      });
      fetchTehnika(); 
    } catch (error) {
      console.error('Ошибка добавления техники:', error);
    }
  };

  const deleteTehnika = async (id) => {
    try {
      await axios.delete(`/api/tehnika/${id}`);
      fetchTehnika();
    } catch (error) {
      console.error('Ошибка удаления техники:', error);
    }
  };

  const editTehnika= (tehnika) => {
    setEditingTehnika(tehnika);
  };

  const updateTehnika = async () => {
    try {
      await axios.put(`/api/tehnika/${editingTehnika.id}`, editingTehnika);
      setEditingTehnika(null);
      fetchTehnika(); 
    } catch (error) {
      console.error('Ошибка обновления техники:', error);
    }
  };

  return (
    <div className="container">
      <h2>Редактирование техники</h2>

      {/* Форма для добавления новой техники */}
      <div className="form-group">
        <h3>Добавить новую технику</h3>
        <input
          type="text"
          className="form-control"
          placeholder="Наименование"
          value={newTehnika.name}
          onChange={(e) => setNewTehnika({ ...newTehnika, name: e.target.value })}
        />
        <textarea
          className="form-control"
          placeholder="Описание"
          value={newTehnika.description}
          onChange={(e) => setNewTehnika({ ...newTehnika, description: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Тип"
          value={newTehnika.type}
          onChange={(e) => setNewTehnika({ ...newTehnika, type: e.target.value })}
        />
        <button className="btn btn-primary mt-2" onClick={addTehnika}>
          Добавить технику
        </button>
      </div>

      {/* Список техники */}
      <h3 className="mt-4">Список техники</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Примечание</th>
            <th>Тип</th>
          </tr>
        </thead>
        <tbody>
          {tehnika.map((tehnika) => (
            <tr key={tehnika.id}>
              <td>{tehnika.name}</td>
              <td>{tehnika.description}</td>
              <td>{tehnika.type}</td>
              <td>
                <button className="btn btn-warning mr-2" onClick={() => editTehnika(tehnika)}>
                  Редактировать
                </button>
                <button className="btn btn-danger" onClick={() => deleteTehnika(tehnika.id)}>
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Форма для редактирования техники */}
      {editingTehnika && (
        <div className="form-group">
          <h3>Редактировать технику</h3>
          <input
            type="text"
            className="form-control"
            placeholder="Наименование"
            value={editingTehnika.name}
            onChange={(e) => setEditingTehnika({ ...editingTehnika, name: e.target.value })}
          />
          <textarea
            className="form-control"
            placeholder="Описание"
            value={editingTehnika.description}
            onChange={(e) => setEditingTehnika({ ...editingTehnika, description: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Тип"
            value={editingTehnika.type}
            onChange={(e) => setEditingTehnika({ ...editingTehnika, type: e.target.value })}
          />
          <button className="btn btn-primary mt-2" onClick={updateTehnika}>
            Сохранить изменения
          </button>
          <button className="btn btn-secondary mt-2" onClick={() => setEditingTehnika(null)}>
            Отмена
          </button>
        </div>
      )}
    </div>
  );
};

export default TehnikaEditor;
