// src/api.js
import axios from 'axios';

// Функция для получения списка организаций
export const fetchOrganizations = async (limit = 10, offset = 0) => {
    try {
        const response = await axios.get(`/api/organizations`, {
            params: { limit, offset }
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching organizations:", error);
        throw error;
    }
};
