import React, { useEffect, useState } from 'react';
import { fetchOrganizations } from '../api';

const OrganizationsList = () => {
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [rows, setRows] = useState([{ id: Date.now() }]);

    useEffect(() => {
        const loadOrganizations = async () => {
            try {
                const data = await fetchOrganizations(10, 0);
                setOrganizations(data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        loadOrganizations();
    }, []);

    const addRow = () => {
        setRows([...rows, { id: Date.now() }]);
    };

    const removeRow = (id) => {
        if (rows.length > 1) {
            setRows(rows.filter(row => row.id !== id));
        } else {
            alert("Невозможно удалить последнюю строку.");
        }
    };

    if (loading) return <p>Loading organizations...</p>;
    if (error) return <p>Error loading organizations: {error.message}</p>;

    return (
        <div className="card">
            <div className="container">
                <h2>Задания</h2>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                            <tbody>
                                {organizations.news_list.map(org => (
                                    <tr key={org.id}>
                                        <td><b>{org.id}</b></td>
                                        <td>{org.service_object_title}</td>
                                        <td>{org.news_type_name}</td>
                                        <td><b>{org.title}</b></td>
                                        <td>{org.text}</td>
                                        <td>{org.custom_fields}</td>
                                        <td>{org.department_title}</td>
                                        <td>{org.status_name}</td>
                                        <td>{org.assigned_user_fio}</td>
                                        <td>{org.expired_date}</td>
                                        <td>{org.service_object_layer_title}</td>
                                        <td>
                                            <button className="btn btn-outline-secondary-2x" type="button" data-bs-toggle="modal" data-bs-target={`#modal-${org.id}`}>
                                                Доходы и расходы
                                            </button>
                                            <div className="modal fade" id={`modal-${org.id}`} tabIndex="-1" aria-labelledby="fullScreenModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-fullscreen">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h1 className="modal-title fs-5" id="fullScreenModalLabel">Доходы и расходы</h1>
                                                            <button className="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body dark-modal">
                                                            <div className="large-modal-header">
                                                                <h6>Ценовые позиции</h6>
                                                            </div>
                                                            <div className="modal-details">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Номер</th>
                                                                                    <th>Контрагент</th>
                                                                                    <th>Статья</th>
                                                                                    
                                                                                    <th>Наименование</th>
                                                                                    <th>Единица измерения</th>
                                                                                    <th>Цена</th>
                                                                                    <th>Кол-во</th>
                                                                                    <th>Кф</th>
                                                                                    <th>Сумма</th>
                                                                                    <th>Действия</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {rows.map((row) => (
                                                                                    <tr key={row.id}>
                                                                                        <td></td>
                                                                                        <td><input type="text" className="form-control" name="region[]" /></td>
                                                                                        <td><input type="text" className="form-control" name="counterparty[]" value="из БД" /></td>
                                                                                        <td><input type="text" className="form-control" name="article[]" /></td>
                                                                                        
                                                                                        <td><input type="text" className="form-control" name="name[]" /></td>
                                                                                        <td><input type="text" className="form-control" name="price[]" /></td>
                                                                                        <td><input type="text" className="form-control" name="unit[]" /></td>
                                                                                        <td><input type="text" className="form-control" name="unit[]" /></td>
                                                                                        <td><input type="text" className="form-control" name="customer_name[]" /></td>
                                                                                       
                                                                                        <td>
                                                                                            <button type="button" className="btn btn-success btn-add" onClick={addRow}>+</button>
                                                                                            <button type="button" className="btn btn-danger btn-remove" onClick={() => removeRow(row.id)}>-</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                                                            <button className="btn btn-primary" type="button">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizationsList;
