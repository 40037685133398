import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/styles.css'; // Подключаем стили

const ArticlesEditor = () => {
  const [articles, setArticles] = useState([]);
  const [newArticle, setNewArticle] = useState({
    name: '',
    type: 'income',
    amount: '',
    description: '',
  });
  const [editingArticle, setEditingArticle] = useState(null);

  // Получение списка статей
  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await axios.get('/api/articles');
      setArticles(response.data.articles);
    } catch (error) {
      console.error('Ошибка получения статей:', error);
    }
  };

  // Добавление новой статьи
  const addArticle = async () => {
    try {
      await axios.post('/api/articles', newArticle);
      setNewArticle({
        name: '',
        type: 'income',
        amount: '',
        description: '',
      });
      fetchArticles(); // обновить список статей
    } catch (error) {
      console.error('Ошибка добавления статьи:', error);
    }
  };

  // Удаление статьи
  const deleteArticle = async (id) => {
    try {
      await axios.delete(`/api/articles/${id}`);
      fetchArticles();
    } catch (error) {
      console.error('Ошибка удаления статьи:', error);
    }
  };

  // Редактирование статьи
  const editArticle = (article) => {
    setEditingArticle(article);
  };

  const updateArticle = async () => {
    try {
      await axios.put(`/api/articles/${editingArticle.id}`, editingArticle);
      setEditingArticle(null);
      fetchArticles(); // обновить список статей
    } catch (error) {
      console.error('Ошибка обновления статьи:', error);
    }
  };

  return (
    <div className="container">
      <h2>Редактирование статей доходов/расходов</h2>

      {/* Форма для добавления новой статьи */}
      <div className="form-group">
        <h3>Добавить новую статью</h3>
        <input
          type="text"
          className="form-control"
          placeholder="Наименование"
          value={newArticle.name}
          onChange={(e) => setNewArticle({ ...newArticle, name: e.target.value })}
        />
        <select
          className="form-control"
          value={newArticle.type}
          onChange={(e) => setNewArticle({ ...newArticle, type: e.target.value })}
        >
          <option value="income">Доход</option>
          <option value="expense">Расход</option>
        </select>
       
        <textarea
          className="form-control"
          placeholder="Описание"
          value={newArticle.description}
          onChange={(e) => setNewArticle({ ...newArticle, description: e.target.value })}
        />
        <button className="btn btn-primary mt-2" onClick={addArticle}>
          Добавить статью
        </button>
      </div>

      {/* Список статей */}
      <h3 className="mt-4">Список статей</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Тип</th>
            <th>Описание</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {articles.map((article) => (
            <tr key={article.id}>
              <td>{article.name}</td>
              <td>{article.type === 'income' ? 'Доход' : 'Расход'}</td>
              <td>{article.description}</td>
              <td>
                <button className="btn btn-warning mr-2" onClick={() => editArticle(article)}>
                  Редактировать
                </button>
                <button className="btn btn-danger" onClick={() => deleteArticle(article.id)}>
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Форма для редактирования статьи */}
      {editingArticle && (
        <div className="form-group">
          <h3>Редактировать статью</h3>
          <input
            type="text"
            className="form-control"
            placeholder="Наименование"
            value={editingArticle.name}
            onChange={(e) => setEditingArticle({ ...editingArticle, name: e.target.value })}
          />
          <select
            className="form-control"
            value={editingArticle.type}
            onChange={(e) => setEditingArticle({ ...editingArticle, type: e.target.value })}
          >
            <option value="income">Доход</option>
            <option value="expense">Расход</option>
          </select>
          <textarea
            className="form-control"
            placeholder="Описание"
            value={editingArticle.description}
            onChange={(e) => setEditingArticle({ ...editingArticle, description: e.target.value })}
          />
          <button className="btn btn-primary mt-2" onClick={updateArticle}>
            Сохранить изменения
          </button>
          <button className="btn btn-secondary mt-2" onClick={() => setEditingArticle(null)}>
            Отмена
          </button>
        </div>
      )}
    </div>
  );
};

export default ArticlesEditor;
