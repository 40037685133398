import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/styles.css'; // Подключаем стили

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
            const data = await response.json();
            // Сохраняем данные пользователя или токен, если нужно
            navigate('/dashboard');
        } else {
            alert('Неверные данные для входа!');
        }
    };

    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-12 p-0">    
                    <div className="login-card login-dark">
                        <div>
                            <div>
                                <a className="logo" href="index.html">
                                    <img className="img-fluid for-light" src="../assets/images/logo.jpg" alt="logo" />
                                    <img className="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="logo" />
                                </a>
                            </div>
                            <div className="login-main"> 
                                <form className="theme-form" onSubmit={handleLogin}>
                                    <h4>Корпоротивный портал smartcoms.ru</h4>
                                
                                    <div className="form-group">
                                        <label className="col-form-label">Логин</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                            placeholder="Логин"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Пароль</label>
                                        <div className="form-input position-relative">
                                            <input
                                                className="form-control"
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                                placeholder="*********"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="text-end mt-3">
                                            <button className="btn btn-primary btn-block w-100" type="submit">Авторизоваться</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
