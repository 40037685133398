import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/styles.css'; // Подключаем стили

const ProjectEditor = () => {
  const [project, setProject] = useState([]);
  const [newProject, setNewProject] = useState({
    name: '',
    description: '',
    client: '',
  });
  const [editingProject, setEditingProject] = useState(null);

  // Получение списка проектов
  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    try {
      const response = await axios.get('/api/project');
      setProject(response.data.project);
    } catch (error) {
      console.error('Ошибка получения проектов:', error);
    }
  };

  // Добавление нового проекта
  const addProject = async () => {
    try {
      await axios.post('/api/project', newProject);
      setNewProject({
        name: '',
        description: '',
        client: '',
      });
      fetchProject(); // обновить список проектов
    } catch (error) {
      console.error('Ошибка добавления проекта:', error);
    }
  };

  // Удаление проекта
  const deleteProject = async (id) => {
    try {
      await axios.delete(`/api/Project/${id}`);
      fetchProject();
    } catch (error) {
      console.error('Ошибка удаления проекта:', error);
    }
  };

  // Редактирование проекта
  const editProject= (project) => {
    setEditingProject(project);
  };

  const updateProject = async () => {
    try {
      await axios.put(`/api/project/${editingProject.id}`, editingProject);
      setEditingProject(null);
      fetchProject(); // обновить список проектов
    } catch (error) {
      console.error('Ошибка обновления проекта:', error);
    }
  };

  return (
    <div className="container">
      <h2>Редактирование проектов</h2>

      {/* Форма для добавления нового проекта */}
      <div className="form-group">
        <h3>Добавить новый проект</h3>
        <input
          type="text"
          className="form-control"
          placeholder="Наименование"
          value={newProject.name}
          onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
        />
        <textarea
          className="form-control"
          placeholder="Описание"
          value={newProject.description}
          onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Заказчик"
          value={newProject.client}
          onChange={(e) => setNewProject({ ...newProject, client: e.target.value })}
        />
        <button className="btn btn-primary mt-2" onClick={addProject}>
          Добавить проект
        </button>
      </div>

      {/* Список проектов */}
      <h3 className="mt-4">Список проектов</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Примечание</th>
            <th>Заказчик</th>
          </tr>
        </thead>
        <tbody>
          {project.map((project) => (
            <tr key={project.id}>
              <td>{project.name}</td>
              <td>{project.description}</td>
              <td>{project.client}</td>
              <td>
                <button className="btn btn-warning mr-2" onClick={() => editProject(project)}>
                  Редактировать
                </button>
                <button className="btn btn-danger" onClick={() => deleteProject(project.id)}>
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Форма для редактирования проектов */}
      {editingProject && (
        <div className="form-group">
          <h3>Редактировать проект</h3>
          <input
            type="text"
            className="form-control"
            placeholder="Наименование"
            value={editingProject.name}
            onChange={(e) => setEditingProject({ ...editingProject, name: e.target.value })}
          />
          <textarea
            className="form-control"
            placeholder="Описание"
            value={editingProject.description}
            onChange={(e) => setEditingProject({ ...editingProject, description: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Заказчик"
            value={editingProject.client}
            onChange={(e) => setEditingProject({ ...editingProject, client: e.target.value })}
          />
          <button className="btn btn-primary mt-2" onClick={updateProject}>
            Сохранить изменения
          </button>
          <button className="btn btn-secondary mt-2" onClick={() => setEditingProject(null)}>
            Отмена
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectEditor;
