import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/styles.css'; // Подключаем стили

const TcpEditor = () => {
  const [tcp, setTcp] = useState([]);
  const [region, setRegions] = useState([]);
  const [project, setProjects] = useState([]);
  const [articles, setArticles] = useState([]);
  const [newTcp, setNewTcp] = useState({
    name: '',
    description: '',
    price: '',
    izmer: '',
    nameclient: '',
    projectid: '',
    punkt: '',
    regionid: '',
  });
  const [editingTcp, setEditingTcp] = useState(null);

  useEffect(() => {
    fetchTcp();
    fetchRegions();
    fetchProjects();
    fetchArticles();
  }, []);

  const fetchTcp = async () => {
    try {
      const response = await axios.get('/api/tcp');
      setTcp(response.data.tcp);
    } catch (error) {
      console.error('Ошибка получения ТЦП:', error);
    }
  };

  const fetchRegions = async () => {
    try {
      const response = await axios.get('/api/region'); // Замените URL на актуальный
      setRegions(response.data.region);
    } catch (error) {
      console.error('Ошибка получения регионов:', error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get('/api/project'); // Замените URL на актуальный
      setProjects(response.data.project);
    } catch (error) {
      console.error('Ошибка получения проектов:', error);
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get('/api/articles');
      setArticles(response.data.articles);
    } catch (error) {
      console.error('Ошибка получения статей:', error);
    }
  };

  const addTcp = async () => {
    try {
      await axios.post('/api/tcp', newTcp);
      setNewTcp({
        name: '',
        description: '',
        price: '',
        izmer: '',
        nameclient: '',
        projectid: '',
        punkt: '',
        regionid: '',
      });
      fetchTcp();
    } catch (error) {
      console.error('Ошибка добавления ТЦП:', error);
    }
  };

  const deleteTcp = async (id) => {
    try {
      await axios.delete(`/api/tcp/${id}`);
      fetchTcp();
    } catch (error) {
      console.error('Ошибка удаления ТЦП:', error);
    }
  };

  const editTcp = (tcp) => {
    setEditingTcp(tcp);
  };

  const updateTcp = async () => {
    try {
      await axios.put(`/api/tcp/${editingTcp.id}`, editingTcp);
      setEditingTcp(null);
      fetchTcp();
    } catch (error) {
      console.error('Ошибка обновления ТЦП:', error);
    }
  };

  return (
    <div className="container">
      <h2>Редактирование ТЦП</h2>

      {/* Форма для добавления новой ТЦП */}
      <div className="form-group">
        <h3>Добавить новую ТЦП</h3>
        <input
          type="text"
          className="form-control"
          placeholder="Наименование"
          value={newTcp.name}
          onChange={(e) => setNewTcp({ ...newTcp, name: e.target.value })}
        />
        <textarea
          className="form-control"
          placeholder="Описание"
          value={newTcp.description}
          onChange={(e) => setNewTcp({ ...newTcp, description: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Цена"
          value={newTcp.price}
          onChange={(e) => setNewTcp({ ...newTcp, price: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Единица измерения"
          value={newTcp.izmer}
          onChange={(e) => setNewTcp({ ...newTcp, izmer: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Наименование для заказчика"
          value={newTcp.nameclient}
          onChange={(e) => setNewTcp({ ...newTcp, nameclient: e.target.value })}
        />
        <select
          className="form-control"
          value={newTcp.articlesid}
          onChange={(e) => setNewTcp({ ...newTcp, articlesid: e.target.value })}
        >
          <option value="">Выберите статью</option>
          {articles.map(article => (
            <option key={article.id} value={article.id}>{article.name}</option>
          ))}
        </select>
        <select
          className="form-control"
          value={newTcp.projectid}
          onChange={(e) => setNewTcp({ ...newTcp, projectid: e.target.value })}
        >
          <option value="">Выберите проект</option>
          {project.map(project => (
            <option key={project.id} value={project.id}>{project.name}</option>
          ))}
        </select>
        <select
          className="form-control"
          value={newTcp.regionid}
          onChange={(e) => setNewTcp({ ...newTcp, regionid: e.target.value })}
        >
          <option value="">Выберите регион</option>
          {region.map(region => (
            <option key={region.id} value={region.id}>{region.name}</option>
          ))}
        </select>
        <input
          type="text"
          className="form-control"
          placeholder="Пункт"
          value={newTcp.punkt}
          onChange={(e) => setNewTcp({ ...newTcp, punkt: e.target.value })}
        />
        <button className="btn btn-primary mt-2" onClick={addTcp}>
          Добавить ТЦП
        </button>
      </div>

      {/* Список ТЦП */}
      <h3 className="mt-4">Список ТЦП</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Регион</th>
            <th>Статья</th>
            <th>Наименование</th>
            <th>Цена</th>
            <th>Единица измерения</th>
            <th>Наименование для заказчика</th>
            <th>Пункт</th>
            <th>Проект</th>
            <th>Примечание</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {tcp.map((tcp) => (
            <tr key={tcp.id}>
              <td>{region.find(r => r.id === tcp.regionid)?.name || 'Не задан'}</td>
              <td>{articles.find(p => p.id === tcp.articlesid)?.name || 'Не задан'}</td>
              <td>{tcp.name}</td>
              <td>{tcp.price}</td>
              <td>{tcp.izmer}</td>
              <td>{tcp.nameclient}</td>
              <td>{tcp.punkt}</td>
              <td>{project.find(p => p.id === tcp.projectid)?.name || 'Не задан'}</td>
              
              <td>{tcp.description}</td>
              <td>
                <button className="btn btn-warning mr-2" onClick={() => editTcp(tcp)}>
                  Редактировать
                </button>
                <button className="btn btn-danger" onClick={() => deleteTcp(tcp.id)}>
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Форма для редактирования ТЦП */}
      {editingTcp && (
        <div className="form-group">
          <h3>Редактировать ТЦП</h3>
          <input
            type="text"
            className="form-control"
            placeholder="Наименование"
            value={editingTcp.name}
            onChange={(e) => setEditingTcp({ ...editingTcp, name: e.target.value })}
          />
          <textarea
            className="form-control"
            placeholder="Описание"
            value={editingTcp.description}
            onChange={(e) => setEditingTcp({ ...editingTcp, description: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Цена"
            value={editingTcp.price}
            onChange={(e) => setEditingTcp({ ...editingTcp, price: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Единица измерения"
            value={editingTcp.izmer}
            onChange={(e) => setEditingTcp({ ...editingTcp, izmer: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Наименование для заказчика"
            value={editingTcp.nameclient}
            onChange={(e) => setEditingTcp({ ...editingTcp, nameclient: e.target.value })}
          />
          <select
            className="form-control"
            value={editingTcp.articlesid}
            onChange={(e) => setEditingTcp({ ...editingTcp, articlesid: e.target.value })}
          >
            <option value="">Выберите статью</option>
            {articles.map(article => (
              <option key={article.id} value={article.id}>{article.name}</option>
            ))}
          </select>
          <select
            className="form-control"
            value={editingTcp.projectid}
            onChange={(e) => setEditingTcp({ ...editingTcp, projectid: e.target.value })}
          >
            <option value="">Выберите проект</option>
            {project.map(project => (
              <option key={project.id} value={project.id}>{project.name}</option>
            ))}
          </select>
          <select
            className="form-control"
            value={editingTcp.regionid}
            onChange={(e) => setEditingTcp({ ...editingTcp, regionid: e.target.value })}
          >
            <option value="">Выберите регион</option>
            {region.map(region => (
              <option key={region.id} value={region.id}>{region.name}</option>
            ))}
          </select>
          <input
            type="text"
            className="form-control"
            placeholder="Пункт"
            value={editingTcp.punkt}
            onChange={(e) => setEditingTcp({ ...editingTcp, punkt: e.target.value })}
          />
          <button className="btn btn-primary mt-2" onClick={updateTcp}>
            Сохранить изменения
          </button>
          <button className="btn btn-secondary mt-2" onClick={() => setEditingTcp(null)}>
            Отмена
          </button>
        </div>
      )}
    </div>
  );
};

export default TcpEditor;
