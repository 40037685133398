import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/styles.css'; // Подключаем стили

const RegionEditor = () => {
  const [region, setRegion] = useState([]);
  const [newRegion, setNewRegion] = useState({
    name: '',
    description: '',
    code: '',
  });
  const [editingRegion, setEditingRegion] = useState(null);

  useEffect(() => {
    fetchRegion();
  }, []);

  const fetchRegion = async () => {
    try {
      const response = await axios.get('/api/region');
      setRegion(response.data.region);
    } catch (error) {
      console.error('Ошибка получения регионов:', error);
    }
  };

  const addRegion = async () => {
    try {
      await axios.post('/api/region', newRegion);
      setNewRegion({
        name: '',
        description: '',
        code: '',
      });
      fetchRegion(); 
    } catch (error) {
      console.error('Ошибка добавления региона:', error);
    }
  };

  const deleteRegion = async (id) => {
    try {
      await axios.delete(`/api/region/${id}`);
      fetchRegion();
    } catch (error) {
      console.error('Ошибка удаления региона:', error);
    }
  };

  const editRegion= (region) => {
    setEditingRegion(region);
  };

  const updateRegion = async () => {
    try {
      await axios.put(`/api/region/${editingRegion.id}`, editingRegion);
      setEditingRegion(null);
      fetchRegion(); 
    } catch (error) {
      console.error('Ошибка обновления региона:', error);
    }
  };

  return (
    <div className="container">
      <h2>Редактирование регионов</h2>

      {/* Форма для добавления новой техники */}
      <div className="form-group">
        <h3>Добавить новый регион</h3>
        <input
          type="text"
          className="form-control"
          placeholder="Наименование"
          value={newRegion.name}
          onChange={(e) => setNewRegion({ ...newRegion, name: e.target.value })}
        />
        <textarea
          className="form-control"
          placeholder="Описание"
          value={newRegion.description}
          onChange={(e) => setNewRegion({ ...newRegion, description: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Код"
          value={newRegion.code}
          onChange={(e) => setNewRegion({ ...newRegion, code: e.target.value })}
        />
        <button className="btn btn-primary mt-2" onClick={addRegion}>
          Добавить регион
        </button>
      </div>

      {/* Список регионов */}
      <h3 className="mt-4">Список регионов</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Примечание</th>
            <th>Код</th>
          </tr>
        </thead>
        <tbody>
          {region.map((region) => (
            <tr key={region.id}>
              <td>{region.name}</td>
              <td>{region.description}</td>
              <td>{region.code}</td>
              <td>
                <button className="btn btn-warning mr-2" onClick={() => editRegion(region)}>
                  Редактировать
                </button>
                <button className="btn btn-danger" onClick={() => deleteRegion(region.id)}>
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Форма для редактирования региона */}
      {editingRegion && (
        <div className="form-group">
          <h3>Редактировать регион</h3>
          <input
            type="text"
            className="form-control"
            placeholder="Наименование"
            value={editingRegion.name}
            onChange={(e) => setEditingRegion({ ...editingRegion, name: e.target.value })}
          />
          <textarea
            className="form-control"
            placeholder="Описание"
            value={editingRegion.description}
            onChange={(e) => setEditingRegion({ ...editingRegion, description: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Код"
            value={editingRegion.code}
            onChange={(e) => setEditingRegion({ ...editingRegion, code: e.target.value })}
          />
          <button className="btn btn-primary mt-2" onClick={updateRegion}>
            Сохранить изменения
          </button>
          <button className="btn btn-secondary mt-2" onClick={() => setEditingRegion(null)}>
            Отмена
          </button>
        </div>
      )}
    </div>
  );
};

export default RegionEditor;
