import React from 'react';

function Dashboard() {
    return (
        <div>
          <nav>
            <ul>
              <li><a href="/organizations">Задачи</a></li>
              <li><a href="/articles">Статьи ТЦП</a></li>
              <li><a href="/region">Регионы</a></li>
              <li><a href="/project">Проекты</a></li>
              <li><a href="/tehnika">Техника</a></li>
              <li><a href="/tcp">ТЦП</a></li>
              <li><a href="/agent">Контрагенты</a></li>
            </ul>
          </nav>
        </div>
    );
}

export default Dashboard;
