import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/styles.css'; // Подключаем стили

const AgentEditor = () => {
  const [agents, setAgents] = useState([]);
  const [projects, setProjects] = useState([]); // Для списка проектов
  const [articles, setArticles] = useState([]); // Для списка статей расхода
  const [newAgent, setNewAgent] = useState({
    name: '',
    description: '',
    role: '',
    namemap: '',
    loginmap: '',
    login: '',
    codeproject: [],
    articlesids: [],
    typepay: '',
    kf: '',
    kf1: '',
    kf2: '',
    kf3: '',
    kf4: '',
  });
  const [editingAgent, setEditingAgent] = useState(null);

  useEffect(() => {
    fetchAgents();
    fetchProjects();
    fetchArticles();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await axios.get('/api/agent');
      setAgents(response.data.agents);
    } catch (error) {
      console.error('Ошибка получения контрагентов:', error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get('/api/project'); // Запрос списка проектов
      setProjects(response.data.projects);
    } catch (error) {
      console.error('Ошибка получения проектов:', error);
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get('/api/articles'); // Запрос списка статей расхода
      setArticles(response.data.articles);
    } catch (error) {
      console.error('Ошибка получения статей расхода:', error);
    }
  };

  const addAgent = async () => {
    try {
      await axios.post('/api/agent', {
        ...newAgent,
        codeproject: newAgent.codeproject.join(','), // Преобразование массива в строку
        articlesids: newAgent.articlesids.join(','), // Преобразование массива в строку
      });
      setNewAgent({
        name: '',
        description: '',
        role: '',
        namemap: '',
        loginmap: '',
        login: '',
        codeproject: [],
        articlesids: [],
        typepay: '',
        kf: '',
        kf1: '',
        kf2: '',
        kf3: '',
        kf4: '',
      });
      fetchAgents(); 
    } catch (error) {
      console.error('Ошибка добавления контрагента:', error);
    }
  };

  const deleteAgent = async (id) => {
    try {
      await axios.delete(`/api/agent/${id}`);
      fetchAgents();
    } catch (error) {
      console.error('Ошибка удаления контрагента:', error);
    }
  };

  const editAgent = (agent) => {
    setEditingAgent({
      ...agent,
      codeproject: agent.codeproject.split(','), // Преобразование строки обратно в массив
      articlesids: agent.articlesids.split(','), // Преобразование строки обратно в массив
    });
  };

  const updateAgent = async () => {
    try {
      await axios.put(`/api/agent/${editingAgent.id}`, {
        ...editingAgent,
        codeproject: editingAgent.codeproject.join(','), // Преобразование массива в строку
        articlesids: editingAgent.articlesids.join(','), // Преобразование массива в строку
      });
      setEditingAgent(null);
      fetchAgents(); 
    } catch (error) {
      console.error('Ошибка обновления контрагента:', error);
    }
  };

  const handleCheckboxChange = (type, id, checked) => {
    if (type === 'project') {
      const updatedProjects = checked
        ? [...newAgent.codeproject, id]
        : newAgent.codeproject.filter((projectId) => projectId !== id);
      setNewAgent({ ...newAgent, codeproject: updatedProjects });
    } else if (type === 'article') {
      const updatedArticles = checked
        ? [...newAgent.articlesids, id]
        : newAgent.articlesids.filter((articleId) => articleId !== id);
      setNewAgent({ ...newAgent, articlesids: updatedArticles });
    }
  };

  return (
    <div className="container">
      <h2>Редактирование Контрагенты</h2>

      {/* Форма для добавления нового контрагента */}
      <div className="form-group">
        <h3>Добавить нового контрагента</h3>
        <input
          type="text"
          className="form-control"
          placeholder="Наименование"
          value={newAgent.name}
          onChange={(e) => setNewAgent({ ...newAgent, name: e.target.value })}
        />
        <textarea
          className="form-control"
          placeholder="Описание"
          value={newAgent.description}
          onChange={(e) => setNewAgent({ ...newAgent, description: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Роль"
          value={newAgent.role}
          onChange={(e) => setNewAgent({ ...newAgent, role: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Наименование map"
          value={newAgent.namemap}
          onChange={(e) => setNewAgent({ ...newAgent, namemap: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Логин map"
          value={newAgent.loginmap}
          onChange={(e) => setNewAgent({ ...newAgent, loginmap: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Логин"
          value={newAgent.login}
          onChange={(e) => setNewAgent({ ...newAgent, login: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Тип оплаты"
          value={newAgent.typepay}
          onChange={(e) => setNewAgent({ ...newAgent, typepay: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ"
          value={newAgent.kf}
          onChange={(e) => setNewAgent({ ...newAgent, kf: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ1"
          value={newAgent.kf1}
          onChange={(e) => setNewAgent({ ...newAgent, kf1: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ2"
          value={newAgent.kf2}
          onChange={(e) => setNewAgent({ ...newAgent, kf2: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ3"
          value={newAgent.kf3}
          onChange={(e) => setNewAgent({ ...newAgent, kf3: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ4"
          value={newAgent.kf4}
          onChange={(e) => setNewAgent({ ...newAgent, kf4: e.target.value })}
        />

        {/* Чекбоксы для выбора проектов */}
        <h4>Выберите проекты</h4>
        {projects.map((project) => (
          <div key={project.id}>
            <input
              type="checkbox"
              checked={newAgent.codeproject.includes(project.id.toString())}
              onChange={(e) =>
                handleCheckboxChange('project', project.id.toString(), e.target.checked)
              }
            />
            <label>{project.name}</label>
          </div>
        ))}
                {/* Чекбоксы для выбора статей расхода */}
                <h4>Выберите статьи расхода</h4>
        {articles.map((article) => (
          <div key={article.id}>
            <input
              type="checkbox"
              checked={newAgent.articlesids.includes(article.id.toString())}
              onChange={(e) =>
                handleCheckboxChange('article', article.id.toString(), e.target.checked)
              }
            />
            <label>{article.name}</label>
          </div>
        ))}

        <button className="btn btn-primary mt-2" onClick={addAgent}>
          Добавить контрагента
        </button>
      </div>

      {/* Список контрагентов */}
      <h3 className="mt-4">Список контрагентов</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Описание</th>
            <th>Роль</th>
            <th>Наименование map</th>
            <th>Логин map</th>
            <th>Логин</th>
            <th>Тип оплаты</th>
            <th>КФ</th>
            <th>КФ1</th>
            <th>КФ2</th>
            <th>КФ3</th>
            <th>КФ4</th>
            <th>Проекты</th>
            <th>Статьи расхода</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {agents.map((agent) => (
            <tr key={agent.id}>
              <td>{agent.name}</td>
              <td>{agent.description}</td>
              <td>{agent.role}</td>
              <td>{agent.namemap}</td>
              <td>{agent.loginmap}</td>
              <td>{agent.login}</td>
              <td>{agent.typepay}</td>
              <td>{agent.kf}</td>
              <td>{agent.kf1}</td>
              <td>{agent.kf2}</td>
              <td>{agent.kf3}</td>
              <td>{agent.kf4}</td>
              <td>
                {projects
                  .filter((project) => agent.codeproject.includes(project.id.toString()))
                  .map((project) => project.name)
                  .join('; ')}
              </td>
              <td>
                {articles
                  .filter((article) => agent.articlesids.includes(article.id.toString()))
                  .map((article) => article.name)
                  .join('; ')}
              </td>
              <td>
                <button className="btn btn-warning mr-2" onClick={() => editAgent(agent)}>
                  Редактировать
                </button>
                <button className="btn btn-danger" onClick={() => deleteAgent(agent.id)}>
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Форма для редактирования контрагента */}
      {editingAgent && (
        <div className="form-group">
          <h3>Редактировать контрагента</h3>
          <input
            type="text"
            className="form-control"
            placeholder="Наименование"
            value={editingAgent.name}
            onChange={(e) => setEditingAgent({ ...editingAgent, name: e.target.value })}
          />
          <textarea
            className="form-control"
            placeholder="Описание"
            value={editingAgent.description}
            onChange={(e) => setEditingAgent({ ...editingAgent, description: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Роль"
            value={editingAgent.role}
            onChange={(e) => setEditingAgent({ ...editingAgent, role: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Наименование map"
            value={editingAgent.namemap}
            onChange={(e) => setEditingAgent({ ...editingAgent, namemap: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Логин map"
            value={editingAgent.loginmap}
            onChange={(e) => setEditingAgent({ ...editingAgent, loginmap: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Логин"
            value={editingAgent.login}
            onChange={(e) => setEditingAgent({ ...editingAgent, login: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Тип оплаты"
            value={editingAgent.typepay}
            onChange={(e) => setEditingAgent({ ...editingAgent, typepay: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="КФ"
            value={editingAgent.kf}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="КФ1"
            value={editingAgent.kf1}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf1: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="КФ2"
            value={editingAgent.kf2}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf2: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="КФ3"
            value={editingAgent.kf3}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf3: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="КФ4"
            value={editingAgent.kf4}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf4: e.target.value })}
          />

          {/* Чекбоксы для редактирования проектов */}
          <h4>Выберите проекты</h4>
          {projects.map((project) => (
            <div key={project.id}>
              <input
                type="checkbox"
                checked={editingAgent.codeproject.includes(project.id.toString())}
                onChange={(e) =>
                  handleCheckboxChange('project', project.id.toString(), e.target.checked)
                }
              />
              <label>{project.name}</label>
            </div>
          ))}

          {/* Чекбоксы для редактирования статей расхода */}
          <h4>Выберите статьи расхода</h4>
          {articles.map((article) => (
            <div key={article.id}>
              <input
                type="checkbox"
                checked={editingAgent.articlesids.includes(article.id.toString())}
                onChange={(e) =>
                  handleCheckboxChange('article', article.id.toString(), e.target.checked)
                }
              />
              <label>{article.name}</label>
            </div>
          ))}

          <button className="btn btn-primary mt-2" onClick={updateAgent}>
            Сохранить изменения
          </button>
          <button className="btn btn-secondary mt-2" onClick={() => setEditingAgent(null)}>
            Отмена
          </button>
        </div>
      )}
    </div>
  );
};

export default AgentEditor;
