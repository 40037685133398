import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import OrganizationsList from './components/OrganizationsList';
import ArticlesEditor from './components/ArticlesEditor';
import ProjectEditor from './components/ProjectEditor';
import TehnikaEditor from './components/TehnikaEditor';
import TcpEditor from './components/TcpEditor';
import RegionEditor from './components/RegionEditor';
import AgentEditor from './components/AgentEditor';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/organizations" element={<OrganizationsList />} />
                <Route path="/articles" element={<ArticlesEditor />} />
                <Route path="/project" element={<ProjectEditor />} />
                <Route path="/tehnika" element={<TehnikaEditor />} />
                <Route path="/tcp" element={<TcpEditor />} />
                <Route path="/region" element={<RegionEditor />} />
                <Route path="/agent" element={<AgentEditor />} />
            </Routes>
        </Router>
    );
}

export default App;
